import { keyBy } from 'lodash'

export function setConfig (state, { config }) {
  state.config = config
}

export function setCategoriesById (state, { categories }) {
  state.categoriesById = keyBy(categories, 'id')
}

export function setCustomAttributes (state, { customAttributes }) {
  state.customAttributesById = keyBy(customAttributes, 'id')
}

export function setUploadingResume (state, uploadingResume) {
  state.uploadingResume = uploadingResume
}

export function setFocused (state, focused) {
  state.focused = focused || null
}

export function setDrawerModel (state, drawerModel) {
  state.drawerModel = drawerModel
}
