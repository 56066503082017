import axios from 'axios'
import shortid from 'shortid'
import stelace from 'hc-core/composables/stelace'
import { keyAfterUploadS3 } from 'hc-core/composables/aws'
import { filter, isEmpty, isUndefined, get, set, merge, values, find, omitBy } from 'lodash'
import { userMetadataMapping, getDisplayName, compactObjectDeep } from 'hc-core/composables/misc'

const stelaceInstance = axios.create({
  baseURL: process.env.STELACE_API_URL,
  headers: {
    authorization: 'Stelace-V1 apiKey=' + process.env.STELACE_PUBLISHABLE_API_KEY
  }
})

// ##### Generic calls #####

export async function read (context, { id }) {
  return await stelace.users.read(id)
}

export async function update ({ dispatch, commit, rootGetters }, { id, attrs }) {
  if (isEmpty(attrs)) throw new Error('User update attrs expected')

  const currentUser = rootGetters['auth/currentUser']
  const shouldUpdateDisplayName = !isUndefined(attrs.firstname) || !isUndefined(attrs.lastname)

  if (shouldUpdateDisplayName) {
    attrs.displayName = getDisplayName(attrs.firstname || currentUser.firstname, attrs.lastname || currentUser.lastname)
  }
  let user = null
  const transformedAttrs = prepareUpdateAttrsMetadata(attrs)
  if (rootGetters['auth/currentUser'].id.startsWith('org_')) {
    const orgId = rootGetters['auth/currentUser'].id
    user = await stelace.users.update(id, transformedAttrs, { stelaceOrganizationId: null })
    dispatch('auth/selectOrganization', { organizationId: orgId }, { root: true })
  } else {
    user = await stelace.users.update(id, transformedAttrs)
    commit('auth/setCurrentUser', { user }, { root: true })
  }

  const isOrganization = user.roles.includes('organization')
  if (isOrganization) {
    const currentOrganizations = rootGetters['user/currentOrganizations']
    const updatedCurrentOrganizations = currentOrganizations.map(org => {
      if (org.id === user.id) return user
      else return org
    })
    commit('auth/setOrganizations', { organizations: updatedCurrentOrganizations }, { root: true })
  } else return user
}

// Remove user(s) and all its beloging entities - be careful while using it
export async function remove ({ dispatch }, { ids }) {
  try {
    await stelace.forward.del('/advanced/userspurge', { ids })
    await dispatch('auth/logout', undefined, { root: true })
    return true
  } catch (e) {
    console.error(e)
    return null
  }
}

// Trigger Affinda parsing process
export async function affindaParseProcess (context, { assetId = null, userId = null, payload = {}, standalone = false, s3FullPath = null }) {
  return await stelace.forward.post('/integrations/affinda/parseprocess', { assetId, userId, payload, standalone, s3FullPath })
}

// ##### Specific calls #####

function prepareUpdateAttrsMetadata (attrs) {
  const newAttrs = Object.assign({}, attrs)
  // Supports root properties only
  const metadataAttrs = Object.keys(userMetadataMapping)
  Object.keys(attrs).forEach(a => {
    if (metadataAttrs.includes(a)) {
      set(newAttrs, userMetadataMapping[a], attrs[a])
      delete newAttrs[a]
    }
  })
  return newAttrs
}

export async function updateOrganization ({ dispatch }, { orgId, attrs = {}, selectAfterCreate = false } = {}) {
  if (isEmpty(attrs)) throw new Error('User update attrs expected')
  await dispatch('auth/selectOrganization', { organizationId: orgId }, { root: true })
  const updatedOrganization = await stelace.users.update(orgId, attrs)
  if (selectAfterCreate) await dispatch('auth/selectOrganization', { organizationId: updatedOrganization.id }, { root: true })
  return updatedOrganization
}

export async function removeImplantation ({ rootGetters, dispatch }, { id }) {
  await dispatch('auth/selectOrganization', { organizationId: id }, { root: true })
  const implantation = filter(rootGetters['auth/userOrganizations'].implantations, (o) => o.id === id)[0]
  // Delete company
  if (implantation.company) {
    await stelace.assets.remove(implantation.company.id)
  }
  await dispatch('auth/selectNaturalUser', undefined, { root: true })
  await stelace.users.remove(implantation.id)
  const remainingImplantation = filter(rootGetters['auth/userOrganizations'].implantations, (o) => o.id !== id)[0]
  await dispatch('auth/selectOrganization', { organizationId: remainingImplantation.id }, { root: true })
  // Delete organisation
  return true
}

// ##############################################################""

export async function createOrganizationMember ({ dispatch, rootGetters }, { user, firstOrgId = undefined }) {
  // Create user
  const password = shortid.generate()
  set(user, 'email', user.email.toLowerCase())
  set(user, 'username', user.email)
  set(user, 'password', password)
  set(user, 'displayName', `${user.firstname} ${user.lastname[0]}.`)
  set(user, 'roles', ['member'])
  const member = await stelace.users.create(user)

  // Generate login token
  const memberToken = await stelaceInstance.post('/auth/login', { username: member.username, password })

  await dispatch('auth/createToken', {
    type: 'memberInvitation',
    // userId: member.id, // Can't check it for another account
    data: {
      type: 'resetPassword',
      memberId: member.id,
      companyName: get(find(rootGetters['auth/userOrganizations'].companies, { ownerId: firstOrgId }), 'name', 'Une organisation'),
      memberToken: memberToken.data,
      email: member.email,
      firstname: member.firstname,
      lastname: member.lastname,
    }
  }, { root: true })
  return member
}

export async function manageUserInOrg ({ dispatch }, { user, orgsRolesMap }) {
  const result = {}
  // Create memeber if needed
  if (!user.id) {
    user = await dispatch('user/createOrganizationMember', { user, firstOrgId: get(Object.keys(omitBy(orgsRolesMap, { roles: ['null'] })), '[0]', undefined) }, { root: true })
    set(result, 'color', 'positive')
    set(result, 'key', 'notification.invitationSent')
  }

  // Make member join
  if (Object.keys(orgsRolesMap).length) {
    for (const orgId in orgsRolesMap) {
      if (orgsRolesMap[orgId].roles[0] === 'null') {
        user = await stelace.users.removeFromOrganization(user.id, orgId)
      } else user = await stelace.users.joinOrganizationOrUpdateRights(user.id, orgId, orgsRolesMap[orgId])
    }
  }
  if (!get(result, 'color', false)) set(result, 'color', 'positive')
  if (!get(result, 'key', false)) set(result, 'key', 'notification.saved')

  set(result, 'user', user)
  return result
}

export async function removeUserFromOrg (context, { userId, organizationId }) {
  if (!userId || !organizationId) return false
  return await stelace.users.removeFromOrganization(userId, organizationId)
}

// ###########################  ORGANIZATIONS ##################################
export async function createOrganization ({ commit, dispatch, rootState, rootGetters }, { attrs = {}, selectAfterCreate = true, stelaceOrganizationId = null } = {}) {
  const naturalUser = rootGetters['auth/currentNaturalUser']

  const transformedAttrs = prepareUpdateAttrsMetadata(attrs)
  transformedAttrs.type = 'organization'
  set(transformedAttrs, 'metadata.instant.contactId', naturalUser.id)

  const createAttrs = Object.assign({}, transformedAttrs)
  const organization = await stelace.users.create(createAttrs, { stelaceOrganizationId: stelaceOrganizationId })

  // wait for some workflows operations to complete in server
  await new Promise(resolve => setTimeout(resolve, 1000))
  const updatedOrganization = await stelace.users.read(organization.id, { stelaceOrganizationId: organization.id })
  commit('auth/setOrganizations', { organizations: values(rootState.organizationsById).concat([updatedOrganization]) }, { root: true })
  if (selectAfterCreate) await dispatch('auth/selectOrganization', { organizationId: updatedOrganization.id }, { root: true })
  return updatedOrganization
}

// Create implantation organization and asset, add naturalOwner to implantation as administrator
export async function createImplantationOrgAndAsset ({ rootGetters, dispatch }, { stelaceOrganizationId, orgOwnerId, ref = null, company } = {}) {
  const implantationOrganization = await dispatch('user/createOrganization', {
    attrs: {
      displayName: `Entreprise ${company.name}`,
      roles: ['administrator', 'editor'],
      metadata: { type: 'implantation' }
    },
    orgOwnerId,
    selectAfterCreate: true,
    stelaceOrganizationId
  }, { root: true })

  let slug = await dispatch('asset/slugifyAsset', { name: company.name }, { root: true })
  // TODO : the following check should be useless now (2023-07-11), but we keep it in security for now
  const existingSlugs = rootGetters['auth/userOrganizations'].companies.map((comp) => { return get(comp, 'customAttributes.slug', null) })
  if (existingSlugs.includes(slug)) slug += shortid.generate()

  const assetAttrs = {
    ownerId: implantationOrganization.id,
    locations: company.locations ?? [],
    customAttributes: { slug },
    assetTypeId: rootGetters['common/companyTypeId'],
    categoryId: company.categoryId ?? 'ctgy_sJ0jJE1v0f1lIQAxv0f',
    active: true,
    validated: true,
  }

  merge(company, assetAttrs)
  if (ref) merge(company, ref)

  const implantationAsset = await dispatch('asset/create', { attrs: company }, { root: true })

  // Try to add Unsplash cover, continue anyway if fail
  try {
    const unsplash = axios.create({
      baseURL: process.env.UNSPLASH_API_URL,
      headers: {
        Authorization: `Client-ID ${process.env.UNSPLASH_ACCESS_KEY}`,
        'Accept-Version': 'v1'
      },
    })
    const rq = await unsplash.request({
      method: 'GET',
      url: 'photos/random',
      params: {
        query: `${get(implantationAsset, 'locations[0].city', '')} ${get(implantationAsset, 'locations[0].country', '')}`,
        orientation: 'landscape',
      }
    })
    const photo = get(rq, 'data', false)
    if (photo) {
      // need to grab
      const dlPhoto = await fetch(`${photo.urls.full}&fit=crop&w=2100&h=600&fm=png`)
      const dlPhotoData = await dlPhoto.blob()

      // Upload Unsplash image to S3
      const uploadConfig = {
        id: implantationAsset.id,
        entity: implantationAsset,
        uploadFolder: 'assets/covers',
        uploadPrefix: 'cover',
        useEntityId: true,
        field: 'metadata._files.cover',
      }
      const fileKey = await keyAfterUploadS3({
        file: new File([dlPhotoData], `cover_${implantationAsset.id}.png`, { type: 'image/png' }),
        entity: get(uploadConfig, 'entity', undefined),
        options: uploadConfig
      })

      // Then update asset
      await dispatch('asset/update', {
        assetId: implantationAsset.id,
        attrs: {
          metadata: {
            _files: {
              cover: fileKey, // Filled after s3 upload
              unsplashCover: {
                photoLink: get(photo, 'links.html', undefined),
                userLink: get(photo, 'user.links.html', undefined),
                userName: get(photo, 'user.name', undefined),
              }
            }
          }
        }
      }, { root: true })

      // Confirm download on Unplash's side
      if (photo.id) await unsplash.request({ method: 'GET', url: `photos/${photo.id}/download` })
    }
  } catch (e) {
    console.error(e)
  }

  return {
    organization: implantationOrganization,
    asset: implantationAsset
  }
}

export async function list (context, { query, page = 1, nbResultsPerPage = 10, orderBy = 'createdDate', order = 'desc', role = undefined, id = undefined, filters = {} }) {
  if (query === '') query = undefined
  return await stelace.forward.get('/users/advanced', {
    nbResultsPerPage,
    page,
    order,
    orderBy,
    role,
    query,
    id,
    ...filters
  })
}

// > ApplicantsListing
// Advanced method listing applicants with enriched filters and data feeding, both for "Vivier" and "CVThèque"
export async function applicantsListing (context, params) {
  return await stelace.forward.get('/users/applicants-listing', compactObjectDeep(params))
}

// > ApplicantsUnlock
// Advanced method allowing to run in one call : cvthequeList creation if not present, check credits amount, creation of log event
export async function applicantsUnlock (context, params) {
  return await stelace.forward.put('/users/applicants-unlock', compactObjectDeep(params))
}
