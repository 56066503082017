import { Buffer } from 'buffer'
import { get, set, forIn, fromPairs, toPairs, isObjectLike } from 'lodash'

// In-between projects routing
const $app = (route) => { return `${process.env.HC_PLATFORM === 'APP' ? '' : process.env.HC_APP_URL}?redirect=${base64Encoder(route)}` }
const $vdc = (route) => { return `${process.env.HC_PLATFORM === 'VDC' ? '' : process.env.HC_VDC_URL}?redirect=${base64Encoder(route)}` }
const $web = (route) => { return `${process.env.HC_PLATFORM === 'WEB' ? '' : process.env.HC_WEB_URL}?redirect=${base64Encoder(route)}` }

const base64Encoder = (route) => { return Buffer.from(JSON.stringify(toPairs(route))).toString('base64') }

const base64Decoder = (route) => {
  const decoded = fromPairs(JSON.parse(Buffer.from(route, 'base64').toString('ascii')))
  // Sub-redirections
  if (get(decoded, 'query.redirect')) set(decoded, 'query.redirect', base64Encoder(get(decoded, 'query.redirect', null)))
  // Passing objects in query in sub-redirections
  forIn(get(decoded, 'query', {}), (value, key) => {
    if (isObjectLike(value)) set(decoded, `query.${key}`, base64Encoder(value))
    // We can't decode arrays or objects because that would result in a [object Object] in URL, decode should be transferred in caller's code
  })
  return decoded
}

const createIntent = ({ social = null, query = null }) => {
  if (!social || ! query) return
  try {
    const baseUrl = {
      x: 'https://x.com/intent/tweet',
      threads: 'https://www.threads.net/intent/post'
    }[social]

    const intentUrl = `${baseUrl}?${new URLSearchParams(query).toString()}`
    if (intentUrl) window.open(intentUrl, '_blank')
    return
  } catch (e) {
    this.useLogger(e)
  }
  return
}

export {
  $app,
  $vdc,
  $web,
  base64Encoder,
  base64Decoder,
  createIntent,
}
