import axios from 'axios'
import shortid from 'shortid'
import { Buffer } from 'buffer'
import { get, has } from 'lodash'
import { useLogger } from 'hc-core/composables/logger.js'
import { sanitizeFilename } from 'hc-core/composables/misc.js'

const publishable = process.env.STELACE_PUBLISHABLE_API_KEY
const urlS3CdnPrefix = `${process.env.AWS_S3_URL}${process.env.AWS_CDN_UPLOAD_PREFIX}`
const apiGatewayMap = (resource) => { // Reflect AWS Gateway API resources
  const map = {
    file: 'file-buffer',
    scan: 'scan',
    upload: 'upload-policy',
  }
  return `${process.env.AWS_API_GATEWAY_URL}${map[resource]}`
}

// TODO slowly but surely need to import the whole aws mixin to a utils

export function getFileUrl (key) {
  return `${urlS3CdnPrefix}/${key ? key.replace(/^\//g, '') : key}`
}

export async function getEmbedFileSrc ({ key, type, preventDl = false, noCache = false }) {
  if (!type || !key) return null
  if (type === 'pdf') {
    const embedStyle = '#toolbar=0&navpanes=0&scrollbar=0&view=FitH'
    return `${getFileUrl(key)}${embedStyle}${noCache ? `?t=${Date.now()}` : ''}`
    // const base64 = await getBufferFromS3(key)
    // if (!base64) { // Try direct blob conversions for largers pdf
    //   const blob = await getBlobFromS3(key)
    //   if (!blob || preventDl) return null // Prevent dl is used to not dl Blob in case of restricted applicant
    //   return `${URL.createObjectURL(blob)}${embedStyle}`
    // }
    // return `data:application/pdf;base64,${base64}${embedStyle}`
  } else if (type === 'image') return getFileUrl(key)
}

export async function getBufferFromS3 (key) {
  try {
    const rq = await axios.get(`${apiGatewayMap('file')}?key=${process.env.AWS_CDN_UPLOAD_PREFIX}/${key}`, { responseType: 'buffer', contentType: 'application/pdf', headers: { 'x-api-key': publishable } })
    if (get(rq, 'data.statusCode', false) === 200 && get(rq, 'data.body.data', false)) {
      return Buffer.from(rq.data.body.data).toString('base64')
    } else throw new Error(rq.data)
  } catch (e) {
    return null
  }
}

// Generic way to download file
export async function dlKeyFromS3 ({ key, type, dlLabel = undefined }) {
  if (!type || !key) return null
  if (type === 'pdf') {
    const blob = await getBlobFromS3(key)
    if (!blob) return null
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = sanitizeFilename(`${dlLabel ?? key.split('/').pop()}_HappyCab.pdf`)
    link.click()
    URL.revokeObjectURL(link.href)
  } else if (type === 'image') {
    // TODO implement this, but should be the same way as pdf
  }
}

// Getting file directly and buffering blob in browser
async function getBlobFromS3 (key) {
  try {
    return await fetch(getFileUrl(key))
      .then(res => res.blob()).then((blob) => {
        if (!['application/pdf', 'application/x-www-form-urlencoded'].includes(blob.type)) throw new Error(blob.type)
        return blob
      }).catch(e => { throw new Error(e) })
  } catch (e) {
    useLogger(e)
    return null
  }
}

// Generic way to upload file to s3
export async function keyAfterUploadS3 ({ file, entity = {}, options = {} }) {
  try {
    if (!file) return null
    let prefix = get(options, 'uploadPrefix', undefined)
    if (prefix === 'timestamp') prefix = Date.now()

    let id = shortid.generate()
    if (get(options, 'uploadEntityId', false)) id = get(options, 'uploadEntityId', id)
    if (get(options, 'useEntityId', false)) id = get(entity, 'id', id)
    if (get(options, 's3KeepFileNameAsIs', false)) {
      prefix = undefined
      id = file.name.split('.')[0]
    }

    // getS3SignedUrl import
    const { url } = await axios.post( // get AWS S3 signed URL from Lambda behind API Getaway
      apiGatewayMap('upload'),
      {
        filename: `${prefix ? `${prefix}_` : ''}${sanitizeFilename(id)}.${file.name.split('.').pop()}`,
        folder: cleanPrefix(process.env.AWS_CDN_UPLOAD_PREFIX) + get(options, 'uploadFolder', 'missingUploadFolder'),
        contentType: options.contentType ?? file.type ?? undefined
      },
      { headers: { 'x-api-key': publishable } }
    ).then(({ data }) => {
      const S3Sign = parseS3Url(data.body)
      const baseUrl = S3Sign.endpoint_url
      const S3FileUrl = `${baseUrl}${S3Sign.key}`

      const fieldsHeaders = [{ name: 'content-type', value: options.contentType ?? file.type }]
      if (options.contentType === 'application/pdf' || file.type === 'application/pdf') fieldsHeaders.push({ name: 'content-disposition', value: 'inline' })

      return {
        S3FileUrl,
        fields: fieldsHeaders,
        url: S3Sign.fullUrl,
        fieldName: 'file' // S3 POST: https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
      }
    })

    const fieldsHeadersAxios = { 'Content-Type': options.contentType ?? file.type }
    if (options.contentType === 'application/pdf' || file.type === 'application/pdf') fieldsHeadersAxios['Content-Disposition'] = 'inline'

    // uploadFileToS3 import
    const response = await axios.put(
      url,
      file,
      { headers: fieldsHeadersAxios }
    )
    const fileUrl = (response.status === 200 && has(response, 'config.url')) ? response.config.url.split('?')[0] : null

    // getFileKey import
    return fileUrl ? fileUrl.replace(`${urlS3CdnPrefix}`, '').replace(/^\//g, '') : null
  } catch (e) {
    return null
  }
}

function cleanPrefix (prefix = '') {
  return prefix
    .replace(/\/{2,}/g, '/')
    .replace(/^\//, '')
    .replace(/([^/]+)(\/)?$/, '$1/') // force trailing slash only if string is not empty
}

function parseS3Url (url) {
  const temp = document.createElement('a')
  temp.href = url
  const baseUrl = temp.origin + '/'
  return {
    endpoint_url: baseUrl,
    fullUrl: url,
    key: unescape(url.split('?')[0].replace(baseUrl, ''))
  }
}

// New, generic, with lambda function, served as cdn, allow noCache, purposed to be used everywhere
// Self reinterpretation of sharp-aws-image-handler-client
export function cdnImg (cdnPath, opt = {}) {
  return `${process.env.AWS_CDN_WITH_IMAGE_HANDLER_URL}${btoa(JSON.stringify({
    bucket: process.env.AWS_CDN_S3_BUCKET,
    key: decodeURIComponent(`${process.env.AWS_CDN_UPLOAD_PREFIX}/${(cdnPath ?? 'platform/branding/placeholder.jpg').replace(/^\//g, '')}`),
    edits: {
      resize: { width: get(opt, 'width', undefined), height: get(opt, 'height', undefined), fit: 'cover' },
      webp: { quality: opt.lossless ? 100 : get(opt, 'quality', 85), lossless: get(opt, 'lossless', false) }
    }
  }))}${get(opt, 'noCache', false) ? `?t=${Date.now()}` : ''}`
}
