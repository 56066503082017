<template>
  <div ref="ImageCropperUploader">
    <QUploader
      v-if="field && entity"
      ref="uploader"
      class="q-uploader--hide-list q-mx-auto"
      :style="computedStyle"
      :factory="factoryAws"
      max-files="1"
      accept="image/*"
      flat
      @added="imageAdded"
      @uploading="isUploading = true"
    >
      <template #header="scope" class="no-bg">
        <div class="relative-position q-pa-none imageUploader" :style="computedStyle">
          <QAvatar v-if="field.isCircle" :size="field.size.width" class="fit" :style="computedStyle">
            <img v-if="imageUrl" :src="imageUrl" loading="lazy" style="object-fit:cover;">
            <div v-else class="fit text-white text-weight-bold flex flex-center" :style="`font-size:5rem; ${bgGradient(entity.id)}`">
              {{ defaultLetter }}
            </div>
          </QAvatar>
          <QImg v-else-if="imageUrl" :src="imageUrl" loading="lazy" :style="computedStyle" spinner-color="primary" />
          <div v-else class="fit text-white text-weight-bold flex flex-center" :style="`font-size:5rem; ${bgGradient(entity.id)}`">
            {{ defaultLetter }}
          </div>
          <div class="absolute-full upload-placeholder relative-position" :style="`${computedStyle} ${field.isCircle ? 'border-radius:1000px' : ''}`" @click="scope.pickFiles">
            <div class="absolute-center">
              <QIcon size="sm" :name="field.icon ? field.icon : 'uil:pen'" />
            </div>
            <QUploaderAddTrigger />
            <ActionTooltip :str="field.hint ? field.hint : 'Importer'" />
          </div>
        </div>
      </template>
    </QUploader>

    <div v-show="uploadingImageSrc">
      <QDialog ref="cropperDialog" :maximized="$q.screen.lt.md" persitent @hide="uploadingImageSrc = ''; $emit('close')">
        <AccountCard path="component.dialogs.media_image_dialog.crop" :bordered="false" icon="uil:crop-alt" style="max-height: 75vh; max-width: 75vw">
          <template #header-right>
            <HCButton v-close-popup is-close />
          </template>
          <QCardSection class="q-pt-none">
            <VueCropper
              ref="cropper"
              class="q-my-md"
              :src="uploadingImageSrc"
              :aspect-ratio="field.aspectRatio !== undefined ? field.aspectRatio : field.isCircle ? 1 : 21 / 6"
              :view-mode="2"
              :movable="false"
              :responsive="true"
              style="max-height: 55vh;"
            />
            <div class="flex items-center justify-between">
              <HcButton label="prompt.cancel_button" @click="uploadingImageSrc = ''; $emit('close')" />
              <div class="q-gutter-x-sm">
                <HcButton variant="disabled" label="components.image_cropper_uploader.rotate_left" @click="$refs.cropper.rotate(-90)" />
                <HcButton variant="disabled" label="components.image_cropper_uploader.rotate_right" @click="$refs.cropper.rotate(90)" />
              </div>
              <HcButton variant="positive" label="prompt.save_button" @click="fireUpload" />
            </div>
          </QCardSection>
        </AccountCard>
      </QDialog>
    </div>
  </div>
</template>

<script>
import { bgGradient } from 'hc-core/composables/misc.js'
import AwsMixins, { getS3SignedUrl, uploadFileToS3, getNoCacheUrl } from 'hc-core/mixins/aws.js'
import 'cropperjs/dist/cropper.css'
import VueCropper from 'vue-cropperjs'

export default {
  components: { VueCropper },
  mixins: [AwsMixins],
  props: {
    entity: {
      type: Object,
      default: null
    },
    field: {
      type: Object,
      default: null
    },
    step: {
      type: Object,
      default: null
    }
  },
  emits: ['refresh', 'close', 'imageUploaded', 'stepResult'],
  data () {
    return {
      imageUrl: '',
      isUploading: false,
      currentEntity: null,
      uploadingImageSrc: '',
    }
  },
  computed: {
    computedStyle () {
      return `max-width:${this.field.size.width}; width:100%; max-height:${this.field.size.height}; height:auto; aspect-ratio:${this.field.aspectRatio !== undefined ? this.field.aspectRatio : this.field.isCircle ? '1' : '21 / 6'}`
    },
    defaultLetter () {
      return this.$_.get(this.entity, 'name', this.$_.get(this.entity, 'firstname', this.$_.get(this.entity, 'id', 'X'))).substring(0, 1).toUpperCase()
    }
  },
  watch: {
    uploadingImageSrc: {
      handler: function (val, oldVal) {
        if (val) this.$refs.cropperDialog.show()
        else this.$refs.cropperDialog.hide()
      }
    }
  },
  mounted () {
    if (this.entity) this.currentEntity = this.entity
    if (this.field) {
      if (this.field.isNew) {
        this.$refs.uploader.pickFiles()
      }
      if (this.$_.get(this.field, 'value.key', false)) { // MediaPictureDialog
        this.imageUrl = getNoCacheUrl(this.field.value.key)
        this.uploadingImageSrc = this.imageUrl
      } else if (this.$_.get(this.currentEntity, this.field.key, false)) this.imageUrl = getNoCacheUrl(this.$_.get(this.currentEntity, this.field.key, false))
    }
  },
  methods: {
    bgGradient,
    getS3SignedUrl,
    imageAdded (added) {
      this.uploadingImageSrc = this.$_.get(added, '[0].__img.src', '')
    },
    async fireUpload () {
      try {
        if (this.isUploading) return
        this.isUploading = true
        const file = this.dataURLtoFile(
          await this.$refs.cropper.getCroppedCanvas({
            width: 2300,
            height: 800,
            imageSmoothingQuality: 'high',
          }).toDataURL('image/png'),
          'tempo_img.png'
        )

        const options = {}
        if (this.$_.get(this.field, 'useEntityId', false) && this.$_.get(this.entity, 'id', false)) options.id = this.entity.id
        else options.id = require('shortid').generate()
        if (this.field.uploadFolder) options.folder = this.field.uploadFolder
        if (this.$_.get(this.field, 'prefix', false)) {
          if (this.field.prefix === 'timestamp') options.prefix = Date.now()
          else options.prefix = this.field.prefix
        }

        const { url } = await this.getS3SignedUrl(file, options)
        const fileUrl = await uploadFileToS3({ url, file })
        this.$emit('imageUploaded', fileUrl)
        if (this.step) this.$emit('stepResult', { canGoNext: true, value: this.$_.set({}, this.step.field, this.getFileKey(fileUrl)) })
        this.imageUrl = fileUrl
        this.uploadingImageSrc = ''
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.uploadingImageSrc = ''
        this.isUploading = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .q-uploader :deep(.q-uploader__header)
    background-color: rgba(0, 0, 0, 0)
  .q-uploader :deep(.q-uploader__list)
    display: none
  .upload-placeholder
    opacity: 0
    transition: all ease 0.5s
    &:hover
      background: rgba(15, 20, 51, 0.5)
      opacity: 1
</style>
