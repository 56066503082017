import { Screen } from 'quasar'
import { compact, get, orderBy, uniqBy } from 'lodash'

export function element (state) {
  return state.element
}
export function applications (state) {
  return state.applications
}
export function applicants (state) {
  return state.applicants
}
export function profileAssets (state) {
  return state.profileAssets
}
export function stages (state) {
  return state.stages
}
export function embed (state) {
  return state.embed
}
export function orderByField (state) {
  return state.orderByField
}
export function orderByOrder (state) {
  return state.orderByOrder
}
export function filterUnread (state) {
  return state.filterUnread
}
export function stageModel (state) {
  return state.stageModel
}
export function activeModel (state) {
  return state.activeModel
}

export function kanban (state) {
  const items = compact(uniqBy(
    orderBy(
      state.applications.map((application) => {
        const applicant = state.applicants.find(applicant => applicant.id === application.takerId) ?? null
        let profile = null
        let conversation = null
        let applicantApplications = null
        if (applicant) {
          profile = state.profileAssets.find(profile => profile.ownerId === applicant.id) ?? null
          applicantApplications = state.applications.filter(app => app.takerId === applicant.id)
          conversation = state.conversations.find(conv => conv.interlocutorId === applicant.id) ?? null
        }
        // Prevent JSON sircular dependency in ApplicantCard memoize call by doing this and not setting in applicant directly
        return {
          ...application,
          applicant: applicant ? {
            ...applicant,
            profileAsset: profile ?? undefined,
            conversation: conversation ?? undefined,
            applications: applicantApplications ?? undefined,
          } : undefined
        }
      }),
      [state.orderByField ?? 'createdDate'],
      [state.orderByOrder ?? 'desc']
    ),
    'id'
  ))

  const stages = compact(state.stages.filter((s) => {
    let stageItems = items.filter(i => s.cond(i))
    if (state.filterUnread.includes(s.value)) {
      stageItems = stageItems.filter(item => get(item, 'applicant.conversation.nbUnread', false))
    }

    if (stageItems.length < 1 && s.removeIfNoItems) return undefined
    else {
      s.items = stageItems
      s.nbUnread = stageItems.reduce(
        (acc, item) => acc + get(item, 'applicant.conversation.nbUnread', 0),
        0,
      )
      return s
    }
  }))

  const inactiveStage = stages.find(s => s.inactiveStage)
  const activeStages = stages.filter(s => !s.inactiveStage)
  const isDesktop = Screen.gt.sm
  const displayedStages = compact(isDesktop ? activeStages : [activeStages.find(s => s.value === (state.stageModel ?? stages[0].value)) ?? activeStages[0]])

  return {
    stages,
    inactiveStage,
    displayedStages
  }
}
