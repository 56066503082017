import { assign, compact, findIndex } from 'lodash'

export function setAppDispProperty (state, params) {
  // Special handling
  if (params === 'reset') {
    params = {
      items: [], // Array of applicants
      // index: 0, // Current viewed applicant's index
      loading: false, // Whole list is loading
      // loadingIndex: [], // List all indexes that are loading
      loadingIds: [], // List all ids that are loading

      tab: 'files', // Open tab of dialog
      showDialog: false, // Display dialog or not
      applicantId: null, // Id of applicant to display - discard id at term.

      canGoPrev: false, // Dialog carousel
      canGoNext: false, // Dialog carousel
    }
  }
  const refItems = compact(params.items ?? state.applicantDisplayerStore.items ?? [])
  const appIdTarget = params.applicantId ?? state.applicantDisplayerStore.applicantId
  const currentIndex = appIdTarget ? findIndex(refItems, { id: appIdTarget }) : 0

  // params = 'goPrevItem' || 'goNextItem'
  if (['goPrevItem', 'goNextItem'].includes(params)) {
    const targetIndex = params === 'goPrevItem' ? currentIndex - 1 : params === 'goNextItem' ? currentIndex + 1 : currentIndex
    if (params === 'goPrevItem' && targetIndex >= 0) { // Left
      params = {
        applicantId: refItems[targetIndex].id,
        canGoPrev: targetIndex > 0,
        canGoNext: targetIndex < (refItems.length - 1)
      }
    } else if (params === 'goNextItem' && targetIndex <= (refItems.length - 1)) { // Right
      params = {
        applicantId: refItems[targetIndex].id,
        canGoPrev: targetIndex > 0,
        canGoNext: targetIndex < (refItems.length - 1)
      }
    }
  } else {
    assign(params, {
      canGoPrev: currentIndex > 0,
      canGoNext: currentIndex < (refItems.length - 1)
    })
  }

  // params = { key : val }
  state.applicantDisplayerStore = assign(state.applicantDisplayerStore, params)
}
