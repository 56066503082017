import stelace from 'hc-core/composables/stelace'
import { compact, get, merge, set, uniq } from 'lodash'
import { deepClean, remapToPaginated } from 'hc-core/composables/misc'

// Common for classic and cvtheque search
function buildSearchObject (filters) {
  const defaultSearchObject = {
    customAttributes: undefined,
    query: undefined,
    categoryId: undefined,
    location: undefined,
    maxDistance: undefined,
    assetTypeId: ['typ_kvY7dVE1XR01kuTwiXR0'],
    nbResultsPerPage: 12,
    page: 1,
    similarTo: undefined,
    createdBefore: undefined,
    createdAfter: undefined,
    updatedBefore: undefined,
    updatedAfter: undefined,
    active: true,
    validated: true,
    getTotalHits: true,
    filter: undefined,
  }
  const searchObject = deepClean(merge(defaultSearchObject, filters))

  // Specific mappings
  const simTo = get(searchObject, 'similarTo', [])
  if (simTo.length) set(searchObject, 'similarTo', Array.isArray(simTo) && simTo.length ? compact(uniq(simTo)) : undefined)
  if (!searchObject.sort) set(searchObject, 'sort', { _createdDate: 'desc' })
  return searchObject
}

export async function search (context, filters) {
  return remapToPaginated(await stelace.search.list(buildSearchObject(filters)))
}

export async function searchCvtheque (context, filters) {
  return await stelace.forward.post('/search-cvtheque', buildSearchObject(filters))
}

export async function companyBySlug ({ rootGetters, dispatch }, { slug }) {
  try {
    if (!slug) return null
    if (!rootGetters['common/companyTypeId']) await dispatch('common/fetchConfig', { forceRefresh: true }, { root: true })
    const company = await stelace.forward.get(`/assets/company/slug/${slug}`, {
      fields: ['offers', 'implantations']
    })
    return company
  } catch (error) {
    return null
  }
}

// Trigger Affinda parsing process
export async function affindaParseProcess (context, { assetId = null, userId = null, payload = {} }) {
  return await stelace.forward.post('/integrations/affinda/parseprocess', { assetId, userId, payload })
}
